import { createStore } from 'vuex'

const defaultState = () => {
  return {
    messages: [],
    sessionId: '',
    socketId: '',
    survey: {
      score: 0,
      star: 0,
      note: '',
      sessionId: '',
      checkValidate: false,
      greetingMessages: [],
    },
    channel: null,
    pusher: null,
    channelId: null,
    service: '',
    spinner_waitData: false,
    messageType: 'text',
    isEndChat: false,
    language: 'th',
  }
}

export default createStore({
  state: defaultState(),
  mutations: {
    updateStoreMessages: function (state, data) {
      state.messages = [...state.messages, data]
    },

    updateMyMessages: function (state, data) {
      for (let i in state.messages) {
        if (state.messages[i].chatNum == data.chatNum) {
          state.messages[i] = data
          return
        }
      }
    },

    updatePusher: function (state, value) {
      state.pusher = value
    },

    updateChannel: function (state, value) {
      state.channel = value
    },

    updateChannelId: function (state, value) {
      state.channelId = value
    },

    updateSocketId: function (state, value) {
      state.socketId = value
    },
    updateMessageType: function (state, value) {
      state.messageType = value
    },
    updateIsEndChat: function (state, value) {
      state.isEndChat = value
    },
    updateLanguage: function (state, value) {
      state.language = value
    },

    resetState: function (state) {
      Object.assign(state, defaultState())
    },
  },
  actions: {},
  getters: {
    getMessages: function (state) {
      return state.messages
    },

    getPusher: function (state) {
      return state.pusher
    },

    getChannel: function (state) {
      return state.channel
    },

    getChannelId: function (state) {
      return state.channelId
    },

    getSocketId: function (state) {
      return state.socketId
    },
    getsessionId: function (state) {
      return state.sessionId
    },
    getMessageType: function (state) {
      return state.messageType
    },
    getIsEndChat: function (state) {
      return state.isEndChat
    },
    getLanguage: function (state) {
      return state.language
    },
  },
  modules: {},
  sessionid: '74EE2A72-EFE6-45A4-9657-6214367933B7',
})
