export default function setTimeChat() {
  function timeCondition(data) {
    for (let i = 0; i < data.length; i++) {
      let msg = data[i]
      let date
      if (msg.date) {
        date = new Date(msg.date)
      } else {
        date = new Date()
      }
      let time = `${addLeadingZero(date.getHours())}:${addLeadingZero(
        date.getMinutes()
      )}`
      msg.time = time
      let nextMsg = data[i + 1]
      if (nextMsg) {
        if (msg.sender == nextMsg.sender) {
          msg.isLastMsg = false
        } else {
          msg.isLastMsg = true
        }
      } else {
        msg.isLastMsg = true
      }
    }
  }

  function addLeadingZero(num) {
    let int = parseInt(num)
    if (num < 10) {
      return '0' + int
    }
    return int
  }
  return { timeCondition }
}
