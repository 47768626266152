<template>
  <div class="justify-content-center">
    <button class="btnActive" :disabled="Enabled">
      {{ Title }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    Title: String,
    Enabled: Boolean,
  },
}
</script>

<style scoped>
.btnActive {
  padding: 0.375rem 3.5rem;
  background-color: #f68b1f;
  border: 2px solid #f68b1f;
  color: #ffffff;
  border-radius: 100px;
  line-height: 1.5;
  font-size: 20px;
  font-weight: 500;
  width: max-content !important;
  height: 48px !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btnActive:hover {
  /* width: 100%; */
  height: 48px !important;
  padding: 0.375rem 3.5rem;
  background-color: #f68b1f;
  border-color: #f68b1f;
  color: #ffffff;
  /* height: 2.75rem; */
  /* display: flex; */
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  line-height: 1.5;
  font-size: 20px;
}
.btnActive:disabled {
  /* width: 100%; */
  height: 48px !important;
  /* padding: 0.5rem 2rem; */
  background-color: #f5f6f7;
  border: none;
  color: #ffffff;
  /* height: 2.75rem; */
  /* display: flex; */
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  opacity: 0.8;
}
</style>
