<template>
  <!-- MESSAGE -->
  <div class="menu-title">
    {{ message }}
  </div>
  <!-- MENU -->
  <div class="menu-driven">
    <div v-for="m in menu" :key="m.id">
      <button
        class="menu-botton"
        @click="
          sendMessage({
            message: m,
            sender: 'customer',
            type: 'text',
          })
        "
      >
        {{ m }}
      </button>
    </div>
  </div>
</template>

<script>
import useSendMessage from '@/use/sendMessage'

export default {
  setup() {
    const { sendMessage } = useSendMessage()
    return {
      sendMessage,
    }
  },
  props: {
    message: {
      type: String,
      default: '',
    },
    menu: {
      type: [],
      default: [],
    },
  },
}
</script>
<style scoped>
.menu-driven {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  text-align: right;
  margin-top: 20px;
}
.menu-botton {
  width: 100%;
  height: 40px;
}
.menu-title {
  text-align: left;
}
</style>
