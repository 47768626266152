<template>
  <div class="message">
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      textplaceholder: '',
      data: '',
    }
  },
}
</script>
<style scoped>
.message {
  max-width: 25vh;
  overflow-wrap: break-word;
}
</style>
