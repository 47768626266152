<template>
  <div class="container d-grid justify-content-center mt-4">
    <div class="text-header d-flex justify-content-center">
      <p class="score-text-header">
        จากประสบการณ์การรับบริการในครั้งนี้โดยรวมแล้วคุณรู้สึกพึงพอใจในการให้บริการระดับใด
      </p>
    </div>
    <div class="score">
      <img
        class="star-score-svg"
        :src="star_01"
        alt="star_01"
        v-show="!Star01Active"
        @click="setStar_01()"
        
      />
      <img
        class="star-score-svg"
        :src="starActive_01"
        alt="star_01"
        v-show="Star01Active"
        @click="setStar_01()"
        
      />

      <img
        class="star-score-svg"
        :src="star_02"
        alt="star_02"
        v-show="!Star02Active"
        @click="setStar_02()"
        
      />
      <img
        class="star-score-svg"
        :src="starActive_02"
        alt="star_02"
        v-show="Star02Active"
        @click="setStar_02()"
        
      />

      <img
        class="star-score-svg"
        :src="star_03"
        alt="star_03"
        v-show="!Star03Active"
        @click="setStar_03()"
        
      />
      <img
        class="star-score-svg"
        :src="starActive_02"
        alt="star_03"
        v-show="Star03Active"
        @click="setStar_03()"
        
      />

      <img
        class="star-score-svg"
        :src="star_04"
        alt="star_04"
        v-show="!Star04Active"
        @click="setStar_04()"
        
      />
      <img
        class="star-score-svg"
        :src="starActive_04"
        alt="star_04"
        v-show="Star04Active"
        @click="setStar_04()"
        
      />

      <img
        class="star-score-svg"
        :src="star_05"
        alt="star_05"
        v-show="!Star05Active"
        @click="setStar_05()"
        
      />
      <img
        class="star-score-svg"
        :src="starActive_05"
        alt="star_05"
        v-show="Star05Active"
        @click="setStar_05()"
        
      />
    </div>
    <div class="score-text">
      <span>แย่กว่าที่คิดไว้มาก</span>
      <span>ดีกว่าที่คิดไว้มาก</span>
    </div>
    <hr />
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dataStar: '',
      star_01: require('@/assets/score/score_01.svg'),
      star_02: require('@/assets/score/score_02.svg'),
      star_03: require('@/assets/score/score_03.svg'),
      star_04: require('@/assets/score/score_04.svg'),
      star_05: require('@/assets/score/score_05.svg'),
      starActive_01: require('@/assets/score/scoreActive_01.svg'),
      starActive_02: require('@/assets/score/scoreActive_01.svg'),
      starActive_03: require('@/assets/score/scoreActive_01.svg'),
      starActive_04: require('@/assets/score/scoreActive_01.svg'),
      starActive_05: require('@/assets/score/scoreActive_01.svg'),
      Star01Active: false,
      Star02Active: false,
      Star03Active: false,
      Star04Active: false,
      Star05Active: false,
    }
  },
  methods: {
    setStar_01() {
      if (this.Star01Active) {
        this.Star01Active = false
      } else {
        this.Star01Active = true
      }
      this.Star02Active = false
      this.Star03Active = false
      this.Star04Active = false
      this.Star05Active = false
    },
    setStar_02() {
      if (this.Star02Active) {
        this.Star02Active = false
      } else {
        this.Star02Active = true
      }
      this.Star01Active = false
      this.Star03Active = false
      this.Star04Active = false
      this.Star05Active = false
    },
    setStar_03() {
      if (this.Star03Active) {
        this.Star03Active = false
      } else {
        this.Star03Active = true
      }
      this.Star01Active = false
      this.Star02Active = false
      this.Star04Active = false
      this.Star05Active = false
    },
    setStar_04() {
      if (this.Star04Active) {
        this.Star04Active = false
      } else {
        this.Star04Active = true
      }
      this.Star01Active = false
      this.Star02Active = false
      this.Star03Active = false
      this.Star05Active = false
    },
    setStar_05() {
      if (this.Star05Active) {
        this.Star05Active = false
      } else {
        this.Star05Active = true
      }
      this.Star01Active = false
      this.Star02Active = false
      this.Star03Active = false
      this.Star04Active = false
    },
  },
}
</script>
<style scoped>
.text-header p {
  font-size: 14px;
  text-align: start;
  margin-bottom: 0;
  width: 19.365rem;
}
.score-text {
  margin: auto;
  margin-top: 1rem;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  width: 19.375rem;
  height: 1.125rem;
}
.score-text span {
  font-size: 12px;
  font-weight: 700;
}
.score-text-header {
  /* จากประสบการณ์การรับบริการในครั้งนี้ โดยรวมแล้วคุณรู้สึกพึงพอใจในการให้บริการระดับใด */
  height: 3rem;
  font-family: 'Ekachon';
  font-weight: 400;
  line-height: 1.5rem;
  color: #002d63;
  /* Inside auto layout */
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
.score {
  margin: auto;
  display: flex;
  height: 3rem;
  width: 19.365rem;
  left: 0px;
  border-radius: 0px;
  margin-top: 1rem;
}
.star-score-svg {
  height: 3rem;
  width: 3rem;
  margin-left: 0.75rem;
}
hr {
  width: 19.375rem;
  /* color: #f68b1f; */
  border: 1px solid #f68b1f;
  opacity: 1 !important;
  margin-top: 1.5rem;
}
</style>
