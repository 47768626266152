<template>
  <div class="message">
    <textarea name="" id="" cols="30" rows="10" v-model="data" placeholder="โปรดระบุเหตุผลที่คุณให้คะแนนดังกล่าว"></textarea>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      textplaceholder: 'โปรดระบุเหตุผลที่คุณให้คะแนนดังกล่าว',
      data: '',
    }
  },
}
</script>
<style scoped>
.message {
  max-width: 25vh;
  overflow-wrap: break-word;
}
/* Frame 56 */

textarea {
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px 12px;
  gap: 10px;

  width: 310px;
  height: 106px;

  background: #FFFFFF;
  border: 1px solid #DFE6EC;
  border-radius: 12px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
</style>
