<template>
  <spinner v-show="spinner_waitData" class="style-spinner"></spinner>
  <div class="wrap">
    <div class="container d-grid justify-content-center mt-4 card-main">
      <div class="card-wrapper">
        <div class="text-header d-flex justify-content-center">
          <p class="score-text-header">
            {{ headers }}
          </p>
        </div>
        <div class="score">
          <img
            class="star-score-svg star-score-svg-1"
            :src="star_01"
            alt="star_01"
            v-show="!Score01Active"
            @click="setScores('1')"
            
          />
          <img
            class="star-score-svg"
            :src="starActive_01"
            alt="star_01"
            v-show="Score01Active"
            @click="setScores('1')"
            
          />

          <img
            class="star-score-svg star-score-svg-2"
            :src="star_02"
            alt="star_02"
            v-show="!Score02Active"
            @click="setScores('2')"
            
          />
          <img
            class="star-score-svg"
            :src="starActive_02"
            alt="star_02"
            v-show="Score02Active"
            @click="setScores('2')"
            
          />

          <img
            class="star-score-svg star-score-svg-3"
            :src="star_03"
            alt="star_03"
            v-show="!Score03Active"
            @click="setScores('3')"
            
          />
          <img
            class="star-score-svg"
            :src="starActive_03"
            alt="star_03"
            v-show="Score03Active"
            @click="setScores('3')"
            
          />

          <img
            class="star-score-svg star-score-svg-4"
            :src="star_04"
            alt="star_04"
            v-show="!Score04Active"
            @click="setScores('4')"
            
          />
          <img
            class="star-score-svg"
            :src="starActive_04"
            alt="star_04"
            v-show="Score04Active"
            @click="setScores('4')"
            
          />

          <img
            class="star-score-svg star-score-svg-5"
            :src="star_05"
            alt="star_05"
            v-show="!Score05Active"
            @click="setScores('5')"
            
          />
          <img
            class="star-score-svg"
            :src="starActive_05"
            alt="star_05"
            v-show="Score05Active"
            @click="setScores('5')"
            
          />
        </div>
        <div class="score-text">
          <span>{{ bad }}</span>
          <span>{{ good }}</span>
        </div>
        <hr />
        <div class="text-header d-flex justify-content-center">
          <p class="score-text-header">
            <!-- จากประสบการณ์การรับบริการในครั้งนี้
            หากมีคนรู้จักกำลังสนใจผลิตภัณฑ์หรือบริการทางการเงิน
            คุณมีแนวโน้มที่จะแนะนำ ttb ในระดับใด -->
            {{ arskForRecomended }}
          </p>
        </div>
        <div class="star" :class="{ star_unset: Star }">
          <img
            class="score-svg s1"
            :src="score_05"
            alt="score_05"
            v-show="!Star05Active"
            @click="setStars('5')"
            
          />
          <img
            class="score-svg"
            :src="scoreActive_05"
            alt="score_05"
            v-show="Star05Active"
            @click="setStars('5')"
            
          />
          <img
            class="score-svg s2"
            :src="score_04"
            alt="score_04"
            v-show="!Star04Active"
            @click="setStars('4')"
            
          />
          <img
            class="score-svg"
            :src="scoreActive_04"
            alt="score_04"
            v-show="Star04Active"
            @click="setStars('4')"
            
          />
          <img
            class="score-svg s3"
            :src="score_03"
            alt="score_03"
            v-show="!Star03Active"
            @click="setStars('3')"
            
          />
          <img
            class="score-svg"
            :src="scoreActive_03"
            alt="score_03"
            v-show="Star03Active"
            @click="setStars('3')"
            
          />
          <img
            class="score-svg s4"
            :src="score_02"
            alt="score_02"
            v-show="!Star02Active"
            @click="setStars('2')"
            
          />
          <img
            class="score-svg"
            :src="scoreActive_02"
            alt="score_02"
            v-show="Star02Active"
            @click="setStars('2')"
            
          />
          <img
            class="score-svg s5"
            :src="score_01"
            alt="score_01"
            v-show="!Star01Active"
            @click="setStars('1')"
            
          />
          <img
            class="score-svg"
            :src="scoreActive_01"
            alt="score_01"
            v-show="Star01Active"
            @click="setStars('1')"
            
          />
        </div>
        <div class="score-text">
          <span>{{ notRecomended }}</span>
          <span>{{ recomended }}</span>
        </div>
        <div class="text-tankyou" v-if="isShowThank_1">
          <!-- <h3>Message 1</h3> -->
          <p>{{ thankyouNPS }}</p>
          <span>
            {{ thankyouNPS321 }}
          </span>
        </div>
        <div class="text-tankyou" v-if="isShowThank_2">
          <!-- <h3>Message 2</h3> -->
          <p>{{ thankyouNPS }}</p>
          <span>
            {{ thankyouNPS321 }}
          </span>
        </div>
        <div class="text-tankyou" v-if="isShowThank_3">
          <!-- <h3>Message 3</h3> -->
          <p>{{ thankyouNPS }}</p>
          <span>
            {{ thankyouNPS321 }}
          </span>
        </div>
        <div class="text-tankyou" v-if="isShowThank_4">
          <!-- <h3>Message 4</h3> -->
          <p>{{ thankyouNPS }}</p>
          <span>
            {{ thankyouNPS4 }}
          </span>
        </div>
        <div class="text-tankyou" v-if="isShowThank_5">
          <p>{{ thankyouFor5 }}</p>
          <span>
            {{ thankyouNPS5 }}
          </span>
        </div>
        <hr />
        <!-- <div v-if="state_thank && !spinner_waitData" class="mt-4 fs-5">
          <span class="text-thanks">{{ thank }}</span>
        </div> -->
        <div v-if="!state_thank">
          <textarea
            name="note"
            id="note"
            cols="30"
            rows="4"
            v-model="note"
            @input="AddNode"
            :placeholder="comments"
            maxlength="255"
          ></textarea>
        </div>
        <div v-if="!state_thank">
          <BaseButton
            :Title="submit"
            class="mt-4"
            @click="summitSurvey"
            :Enabled="enableSubmit"
          />
        </div>
        <div v-if="state_thank && !spinner_waitData && !isEx">
          <BaseButton
            Title="ออกจากการสนทนา"
            class="mt-4"
            @click="closeWidget"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Spinner from '../views/Spinner.vue'
import axios from 'axios'
import config from '../config'
export default {
  components: {
    // Spinner,
  },
  props: {
    message: {
      type: String,
      default: '',
    },
    isEx: {
      type: Boolean,
      default: false,
    },
    title: String,
  },
  computed: {
    enableSubmit() {
      if (this.score != 0 && this.star != 0) {
        return false
      }
      return true
    },
    headers() {
      if (this.$store.state.language == 'th') {
        return 'จากประสบการณ์การรับบริการในครั้งนี้ โดยรวมแล้วคุณรู้สึกพึงพอใจในการให้บริการระดับใด'
      } else {
        return 'From your experience on using the service, how would you rate your overall satisfaction?'
      }
    },
    bad() {
      if (this.$store.state.language == 'th') {
        return 'แย่กว่าที่คิดไว้มาก'
      } else {
        return 'Worse than expected'
      }
    },
    good() {
      if (this.$store.state.language == 'th') {
        return 'ดีกว่าที่คิดไว้มาก'
      } else {
        return 'Better than expected'
      }
    },
    arskForRecomended() {
      if (this.$store.state.language == 'th') {
        return 'จากประสบการณ์การรับบริการในครั้งนี้ หากมีคนรู้จักกำลังสนใจผลิตภัณฑ์หรือบริการทางการเงิน คุณมีแนวโน้มที่จะแนะนำ ทีทีบี ในระดับใด'
      } else {
        return 'Regarding your experience on using the service, if your friend or family is interested in banking products or services, to what extent would you recommend ttb?'
      }
    },
    recomended() {
      if (this.$store.state.language == 'th') {
        return 'จะแนะนำอย่างแน่นอน'
      } else {
        return 'Definitely recommend'
      }
    },
    notRecomended() {
      if (this.$store.state.language == 'th') {
        return 'จะไม่แนะนำอย่างแน่นอน'
      } else {
        return 'Definitely not recommend'
      }
    },
    comments() {
      if (this.$store.state.language == 'th') {
        return 'ความคิดเห็นเพิ่มเติม'
      } else {
        return 'Additional comments'
      }
    },
    submit() {
      if (this.$store.state.language == 'th') {
        return 'ส่งข้อมูล'
      } else {
        return 'Submit'
      }
    },
    thankyouNPS() {
      if (this.$store.state.language == 'th') {
        return 'ทีทีบี ขอบคุณสำหรับการให้คะแนน'
      } else {
        return 'Thank you for your review'
      }
    },
    thankyouFor5() {
      if (this.$store.state.language == 'th') {
        return 'ทีทีบี ขอบคุณสำหรับการให้คะแนน'
      } else {
        return 'Thank you for sharing your experience with us'
      }
    },
    thankyouNPS5() {
      if (this.$store.state.language == 'th') {
        return `เรายินดีเป็นอย่างยิ่งที่คุณรู้สึกประทับใจ\nและจะแนะนำคนรู้จักให้ใช้บริการของ ทีทีบี`
      } else {
        return 'We are pleased to know that you are satisfied with our service\nand will recommend ttb to others.'
      }
    },
    thankyouNPS4() {
      if (this.$store.state.language == 'th') {
        return 'เราจะนำข้อเสนอแนะของคุณไปปรับปรุง\nและพัฒนาการให้บริการทำให้คุณรู้สึกประทับใจ\nมากยิ่งขึ้นและจะแนะนำคนรู้จักให้ใช้บริการของเราอย่างแน่นอน'
      } else {
        return 'Your feedback will help us improve service quality\nto make you satisfied and recommend ttb to others.'
      }
    },
    thankyouNPS321() {
      if (this.$store.state.language == 'th') {
        return 'ขออภัยในความไม่สะดวกที่เกิดขึ้น\nเราจะนำข้อเสนอแนะของคุณไปปรับปรุง\nและพัฒนาการให้บริการให้ดีขึ้นต่อไป'
      } else {
        return 'We apologize for the inconvenience.\nYour feedback will help us to improve our service quality.'
      }
    },
    thank() {
      if (this.$store.state.language == 'th') {
        return 'ขอบคุณครับ'
      } else {
        return 'Thank you'
      }
    },
  },
  data() {
    return {
      data: '',
      dataStar: '',
      score_01: require('@/assets/score/score_01.svg'),
      score_02: require('@/assets/score/score_02.svg'),
      score_03: require('@/assets/score/score_03.svg'),
      score_04: require('@/assets/score/score_04.svg'),
      score_05: require('@/assets/score/score_05.svg'),
      scoreActive_01: require('@/assets/score/scoreActive_01.svg'),
      scoreActive_02: require('@/assets/score/scoreActive_02.svg'),
      scoreActive_03: require('@/assets/score/scoreActive_03.svg'),
      scoreActive_04: require('@/assets/score/scoreActive_04.svg'),
      scoreActive_05: require('@/assets/score/scoreActive_05.svg'),
      Score01Active: false,
      Score02Active: false,
      Score03Active: false,
      Score04Active: false,
      Score05Active: false,

      star_01: require('@/assets/score/score_01.svg'),
      star_02: require('@/assets/score/score_02.svg'),
      star_03: require('@/assets/score/score_03.svg'),
      star_04: require('@/assets/score/score_04.svg'),
      star_05: require('@/assets/score/score_05.svg'),
      starActive_01: require('@/assets/score/scoreActive_01.svg'),
      starActive_02: require('@/assets/score/scoreActive_02.svg'),
      starActive_03: require('../assets/score/scoreActive_03.svg'),
      starActive_04: require('@/assets/score/scoreActive_04.svg'),
      starActive_05: require('@/assets/score/scoreActive_05.svg'),
      Star01Active: false,
      Star02Active: false,
      Star03Active: false,
      Star04Active: false,
      Star05Active: false,
      state_thank: false,
      isShowThank_1: false,
      isShowThank_2: false,
      isShowThank_3: false,
      isShowThank_4: false,
      isShowThank_5: false,
      // scrollBar_Active: false,
      score: 0,
      star: 0,
      Star: false,
      oldStar: 0,
      note: '',
      disabledSubmit: true,
      spinner_waitData: true,
      sessionid: this.$route.params.session,
      surveyexpire: false,
    }
  },
  updated() {
    this.$store.state.survey.score = this.star
    this.$store.state.survey.star = this.score
    this.$store.state.survey.note = this.note
    this.$store.state.survey.sessionId = this.$route.params.session
    if (this.score != 0 && this.star != 0) {
      this.disabledSubmit = false
    }
    this.$emit('surveyClick')
    // this.$store.state.survey.star = this.star
    // this.$store.state.survey.note = this.note
  },

  methods: {
    keydown(){

    },
    autoclose() {
      try {
        var config = {
          requestor: 'chatsurvey',
          app_key: 'd930feffba0400d219c339702944b2d4',
        }

        // eslint-disable-next-line no-undef
        var oneappBridge = new $.OneAppBridge(config)
        setTimeout(() => {
          oneappBridge.close()
        }, 4000)
      } catch (error) {
        alert(error)
      }
    },
    setScores(x) {
      if (this.isSubmit) {
        return
      }
      if (!this[`Score0${x}Active`]) {
        for (let i = 1; i <= 5; i++) {
          if (i == x) {
            this[`Score0${i}Active`] = true
          } else {
            this[`Score0${i}Active`] = false
          }
        }

        this.score = parseInt(x)
        this.summitSurveyAllAction()
        //  this.validate()
      }
    },

    setStars(x) {
      if (this.isSubmit) {
        return
      }

      if (!this[`Star0${x}Active`]) {
        for (let i = 1; i <= 5; i++) {
          if (i == x) {
            this[`Star0${i}Active`] = true
          } else {
            this[`Star0${i}Active`] = false
          }
        }

        this.star = parseInt(x)
        this.summitSurveyAllAction()
      }
    },
    AddNode() {
      this.note
    },
    setDatasummitSurvey() {
      let data = {
        osat_score: this.score ? this.score : 0,
        nps_score: this.star ? this.star : 0,
        verbatim: this.note ? this.note : '-',
        session_id: this.sessionid,
      }
      return data
    },
    summitSurveyAllAction() {
      let data = this.setDatasummitSurvey()
      axios
        .post(`${config.baseUrl + config.submitSurveyEndpoint}`, data)
        .then((res) => {
          // eslint-disable-next-line no-empty
          if (res.status == 200) {
          }
        })
      /* .catch((error) => {
          this.errorPage(error)
        }) */
    },
    checksurveyexpire() {
      this.$store.state.spinner_waitData = true
      let data = {
        session_Id: this.$route.params.session,
      }
      if (this.$route.params.session) {
        return new Promise((resolve) => {
          axios
            .post(`${config.baseUrl + config.checksurveyexpireEndpoint}`, data)
            .then((res) => {
              this.spinner_waitData = false
              this.$store.state.spinner_waitData = false
              // console.log(res.status)
              // console.log(res.is_expired)
              // console.log(res.data.status)
              if (res.data.is_expired) {
                this.surveyexpire = true
                this.surveyexpirePage()
                resolve(res.data)
              }
            })
            .catch((error) => {
              console.log(error)
              this.spinner_waitData = false
              this.$store.state.spinner_waitData = false
              // this.errorPage(error)
            })
        })
      }
    },
    summitSurvey() {
      if(this.score != 0 && this.star != 0){
        this.isSubmit = true
        this[`isShowThank_${this.star}`] = true
        this.spinner_waitData = true
        this.$store.state.spinner_waitData = true
        this.state_thank = true
        let data = this.setDatasummitSurvey()
        axios
         .post(`${config.baseUrl + config.submitSurveyEndpoint}`, data)
         .then((res) => {
          this.spinner_waitData = false
          this.$store.state.spinner_waitData = false
          if (res.status == 200) {
            // auto close
            // 4 sec
            this.autoclose()
          }
        })
        .catch((error) => {
          console.log(error)
          this.spinner_waitData = false
          this.$store.state.spinner_waitData = false
          this.autoclose()
        })
      }
 
    },

    errorPage(error) {
      console.log(error)
      /* this.$router.push('/error-page') */
    },
    surveyexpirePage() {
      this.$router.push('/surveyexpire')
    },
    closeWidget() {
      window.parent.postMessage(
        {
          closeWidget: true,
        },
        '*'
      )
    },
  },
  mounted() {
    this.$store.state.survey.sessionId = this.$route.params.session
    this.$store.state.language = this.$route.params.language
    this.checksurveyexpire()
    const oneappBridgeScript = document.createElement('script')
    oneappBridgeScript.setAttribute(
      'src',
      'https://firebasestorage.googleapis.com/v0/b/oneapp-vit.appspot.com/o/OneAppBridge%2Fv%202.6%2Foneapp_bridge.js?alt=media&token=43f96767-7446-4746-8a51-6cd9bf5a6282'
    )
    document.head.appendChild(oneappBridgeScript)
  },
}
</script>
<style scoped>
.style-spinner {
  position: absolute;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.7);
  max-height: 100vh;
  width: 100vw;
}
/* style */
.text-thanks {
  color: #002d63;
  font-weight: 700;
}
.card-main {
  max-width: 1200px;
  min-width: 19.365rem;
}
.card-wrapper {
  width: 18.365rem;
}
.text-header p {
  font-size: 14px;
  text-align: start;
  margin-bottom: 0;
}
.score-text {
  margin: auto;
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  height: 1.125rem;
}
.score-text span {
  font-family: 'Ekachon';
  font-weight: 200 !important;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #002d63;
}
.score-text-header {
  font-family: 'Ekachon';
  font-weight: bold;
  font-size: 16px !important;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #002d63;
}
.score {
  margin: auto;
  display: flex;
  justify-content: center;
  left: 0px;
  border-radius: 0px;
  margin-top: 1rem;
}
.score-svg {
  height: 40px;
  width: 40px;
  margin-left: 0.25rem;
  margin-right: 0.54375rem;
}
.star-score-svg {
  height: 40px;
  width: 40px;
  margin-left: 0.25rem;
  margin-right: 0.54375rem;
}

.star {
  margin: auto;
  display: flex;
  justify-content: center;
  left: 0px;
  border-radius: 0px;
  margin-top: 1rem;
  direction: rtl;
}

.star_unset {
  margin: auto;
  display: flex;
  left: 0px;
  border-radius: 0px;
  margin-top: 1rem;
  /* direction: unset; */
}

.star img {
  transition: all 0.5s;
}

hr {
  width: 110%;
  position: relative;
  left: -16px;
  height: 8px;
  color: #f5f6f7;
  margin: 32px 0px !important;
  opacity: 1 !important;
}
.text-tankyou {
  margin-top: 32px !important;
  text-align: center;
  white-space: pre-line;
}
.text-tankyou p {
  font-family: 'Ekachon';
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #002d63;
  margin-bottom: 8px !important;
}

.text-tankyou span {
  font-family: 'Ekachon';
  font-weight: 200;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
  color: #7d8999;
}
textarea {
  width: 100%;
  height: 84px !important;
  border: 1px solid #b0bccb;
  border-radius: 12px;
  color: #002d63;
  padding: 1rem 0.75rem;
  /* margin-top: 0.5rem; */
  font-size: 16px;
}

::-webkit-input-placeholder {
  color: #b0bccb;
}

textarea:focus {
  outline: 1px solid #0050f0;
}

@media screen and (max-width: 540px) {
  .score-svg {
    margin-right: 0.5rem !important;
  }
  .star-score-svg {
    margin-right: 0.5rem !important;
  }
  .wrap {
    display: flex;
    margin: auto 16px;
  }
  .card-wrapper {
    padding: 0 !important;
    width: 100% !important;
    height: auto;
  }
  .score-text {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }
  textarea {
    width: 100%;
    height: 84px !important;
    padding: 1rem 0.75rem 1rem 0.75rem;
  }
}

@media screen and (min-width: 435px) {
  hr {
    width: 108%;
  }
}

@media screen and (max-width: 720px) {
  .content {
    width: 100% !important;
  }

  .score-svg {
    margin-right: 0.5rem !important;
  }
  .star-score-svg {
    margin-right: 0.5rem !important;
  }
  .wrap {
    display: flex;
    margin: 0px 16px 24px;
  }
  .card-wrapper {
    padding: 0 !important;
    width: 100% !important;
    height: auto;
  }

  .score-text {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }
  textarea {
    width: 100%;
    height: 84px !important;
    padding: 1rem 0.75rem 1rem 0.75rem;
  }
}

@media screen and (min-width: 720px) {
  * {
    margin: 0;
    padding: 0;
  }
  .card-wrapper {
    width: 100%;
    padding: 0 1rem 1.5rem 1rem;
  }
  .score {
    display: flex;
    justify-content: center;
  }
  .score-text {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .score-text span {
    font-size: 12px;
    font-weight: 700;
  }
  .score-svg {
    margin-left: 0.25rem;
    margin-right: 1.5rem;
  }
  .star-score-svg {
    margin-left: 0.25rem;
    margin-right: 1.5rem;
  }
  hr {
    width: 104%;
  }
}

@media screen and (max-width: 1196.98px) {
  * {
    margin: 0;
    padding: 0;
  }
  .card-wrapper {
    width: 100%;
    padding: 0 1rem 1.5rem 1rem;
  }
  .score {
    display: flex;
    justify-content: center;
  }
  .score-text {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .score-text span {
    font-size: 12px;
    font-weight: 700;
  }
  .score-svg {
    margin-left: 0.25rem;
    margin-right: 1.5rem;
  }
  .star-score-svg {
    margin-left: 0.25rem;
    margin-right: 1.5rem;
  }
}

@media screen and (min-width: 1200px) {
  * {
    margin: 0;
    padding: 0;
  }
  .card-main {
    width: 31.4375rem;
    margin-left: 3.5rem;
    margin-top: 1.5rem;
  }
  .score {
    display: flex;
    justify-content: center;
  }
  .star-score-svg {
    margin-left: 10px;
    margin-right: 0;
  }
  .score-svg {
    margin-left: 10px;
    margin-right: 0;
  }
  .score-text {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .score-text span {
    font-size: 12px;
    font-weight: 700;
  }
  .text-header p {
    font-size: 16px;
    line-height: 28px;
  }
  textarea {
    width: 100%;
    height: 84px !important;
  }
  .style-spinner {
    position: absolute;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.7);
    max-height: 100vh;
    width: 100vw;
    margin-top: -1.5rem;
  }
  hr {
    width: 107%;
  }

  @media screen and (max-width: 520px) {
    .style-spinner {
      margin-top: 0;
    }
  }
}
</style>
