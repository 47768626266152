<template>
  <div class="input">
    <input
      class="form-control"
      id="uploadFile"
      type="file"
      accept="image/png, image/jpeg"
      @change="$emit('on-file-change', $event)"
    />
    <div class="chat-submit">
      <input
        class="form-control"
        type="text"
        :value="modelValue"
        :placeholder="label"
        @input="$emit('update:modelValue', $event.target.value)"
      />
      <button type="submit">Send</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
  },
}
</script>
<style scoped>
.input {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}
.chat-submit {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
}
</style>
