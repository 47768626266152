<template>
  <div class="message-file d-flex align-items-center" @click="downloadFile">
    <div class="icon-border me-2">
      <img :src="fileIcon" alt="" />
    </div>
    <div class="d-flex flex-column">
      <div class="name">{{ name }}</div>
      <div class="size">{{ size }}</div>
    </div>
    <a ref="download" target="_blank" :href="message" :download="name"></a>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
    },
    name: {
      type: String,
    },
    size: {
      type: String,
    },
  },
  data() {
    return {
      fileIcon: require('@/assets/file.svg'),
    }
  },

  methods: {
    downloadFile() {
      this.$refs.download.click()
    },
  },
}
</script>

<style scoped>
.message-file {
  width: 25vh;
}

.icon-border {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background-color: #ffffff;
  border-radius: 12px;
}

.name {
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.size {
  font-size: 12px;
  color: #dfe6ec;
}
</style>
