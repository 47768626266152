import { createRouter, createWebHistory } from 'vue-router'
// import Chat from '../views/Chat.vue'
// import BaseTextarea from '../components/BaseTextarea.vue'
// import BaseStar from '../components/BaseStar.vue'
// import PreScreen from '../views/PreScreen.vue'
// import Spinner from '../views/Spinner.vue'
// import CheckAgent from '../views/CheckAgent.vue'
// import SorryPage from '../views/SorryPage.vue'
// // import TestComp from '../views/TestComp.vue'
// import ErrorPage from '../views/ErrorPage.vue'
// import NoAgen from '../views/NoAgent.vue'
// import Survey from '../views/SurveyPage.vue'
// import SurveyWeb from '../views/SurveyWeb.vue'
// import SurveyExpireWeb from '../views/SurveyExpireWeb.vue'

const Chat = () => import('../views/Chat.vue');
const BaseTextarea = () => import('../components/BaseTextarea.vue');
const BaseStar = () => import('../components/BaseStar.vue');
const PreScreen = () => import('../views/PreScreen.vue');
const Spinner = () => import('../views/Spinner.vue');
const CheckAgent = () => import('../views/CheckAgent.vue');
const SorryPage = () => import('../views/SorryPage.vue');
const ErrorPage = () => import('../views/ErrorPage.vue');
const NoAgen = () => import('../views/NoAgent.vue');
const Survey = () => import('../views/SurveyPage.vue');
const SurveyWeb = () => import('../views/SurveyWeb.vue');
const SurveyExpireWeb = () => import('../views/SurveyExpireWeb.vue');

import store from '../store'

function redirectToCheckagent(to, from, next) {
  if (typeof from.name == 'undefined') {
    next({
      name: 'checkAgent',
    })
  } else {
    next()
  }
}

function beforeEnter(to, from, next) {
  store.commit('resetState')
  next()
}

const routes = [
  {
    path: '/',
    name: 'checkAgent',
    component: CheckAgent,
    beforeEnter: beforeEnter,
  },
  {
    path: '/chat/:session/',
    name: 'Chat',
    component: Chat,
    props: true,
    beforeEnter: redirectToCheckagent,
  },
  {
    path: '/check-agent',
    name: 'CheckAgent',
    component: CheckAgent,
  },
  {
    path: '/survey/:session/:language',
    name: 'Survey',
    component: Survey,
  },
  {
    path: '/surveyweb/:session/:language',
    name: 'Surveyweb',
    component: SurveyWeb,
  },
  {
    path: '/surveyexpire',
    name: 'SurveyExpire',
    component: SurveyExpireWeb,
  },
  {
    path: '/BaseTextarea',
    name: 'BaseTextarea',
    component: BaseTextarea,
  },
  {
    path: '/BaseStar',
    name: 'BaseStar',
    component: BaseStar,
  },
  {
    path: '/PreScreen',
    name: 'PreScreen',
    component: PreScreen,
    //   beforeEnter: redirectToCheckagent,
  },
  {
    path: '/Spinner',
    name: 'Spinner',
    component: Spinner,
  },
  {
    path: '/check-agent',
    name: 'CheckAgent',
    component: CheckAgent,
  },
  {
    path: '/error-page',
    name: 'ErrorPage',
    component: ErrorPage,
  },
  {
    path: '/sorry-page',
    name: 'SorryPage',
    component: SorryPage,
  },
  {
    path: '/no-agen',
    name: 'NoAgen',
    component: NoAgen,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
