<template>
  <div class="app-vue">
    <router-view />
  </div>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->
</template>
<style type="text/css">
@font-face {
  font-family: Ekachon;
  src: url('assets/fonts/Ekachon-Medium.ttf');
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: Ekachon;
  src: url('assets/fonts/Ekachon-Bold.ttf');
  font-weight: bold;
  font-display: swap;
}
@font-face {
  font-family: Ekachon;
  src: url('assets/fonts/Ekachon-Regular.ttf');
  font-weight: 200;
  font-display: swap;
}
</style>
<style>
.app-vue {
  font-family: Ekachon;
}
#app {
  font-family: Ekachon;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
