<template>
  <!-- {{ messageType }} -->
  <div
    class="input-zone"
    v-show="messageType != 'warning endchat' && !isEndChat"
  >
    <input
      ref="file"
      id="uploadFile"
      type="file"
      accept=".pdf,.png,.jpg"
      @change="$emit('on-file-change', $event)"
      style="display: none"
    />
    <div @click="$refs.file.click()" class="upload-btn">
      <img class="img-upload-file" :src="uploadfile" />
      <span class="upload-tooltip">แนบไฟล์</span>
    </div>
    <div class="input-message">
      <input
        class="input-box h-100"
        type="text"
        :value="modelValue"
        :placeholder="label"
        @input="$emit('update:modelValue', $event.target.value)"
      />
      <button class="sendmessage" type="submit">
        <img class="img-send-message" :src="sendmessage" />
      </button>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/runtime-core'
import { useStore } from 'vuex'
export default {
  setup() {
    const store = useStore()

    return {
      isEndChat: computed(() => store.getters.getIsEndChat),
    }
  },

  props: {
    label: {
      type: String,
      default: '',
    },
    messageType: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
  },
  updated() {
    // this.messageType = this.$store.state.messageType
  },
  data() {
    return {
      sendmessage: require('@/assets/sendmessage.svg'),
      uploadfile: require('@/assets/uploadfile.svg'),
      // messageType: this.$store.state.messageType,
    }
  },
}
</script>
<style scoped>
.uploadfile-svg {
  padding-top: 13px;
  padding-right: 15px;
}

.input-zone {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.input-message {
  /* Input */
  width: 271px;
  height: 44px;
  background: #f5f4f6;
  border-radius: 50px;
  display: flex;
  justify-content: space-between;
  padding: 7px 12px;
}

.upload-btn {
  cursor: pointer;
  position: relative;
  display: inline-block;
}

.upload-tooltip {
  visibility: hidden;
  position: absolute;
  bottom: 125%;
  left: -26%;
  z-index: 1;
  white-space: nowrap;
  border-radius: 6px;
  background-color: #7d8999;
  color: #fff;
  padding: 4px 8px;
  font-size: 14px;
}

.upload-tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 20%;
  border-width: 5px;
  border-style: solid;
  border-color: #7d8999 transparent transparent transparent;
}

.upload-btn:hover .upload-tooltip {
  visibility: visible;
}

.input-box {
  border: none;
  background: #f5f4f6;
}

input[type='text']::placeholder {
  color: #c7c7c7;
}

input[type='text']:focus {
  outline: none;
}

.sendmessage {
  border: none;
  background: #f5f4f6;
}
</style>
