import axios from 'axios'
import { ref } from 'vue'
import config from '@/config'
import { useStore } from 'vuex'

export default function sendMessage() {
  const inputMessage = ref('')
  const files = ref({})
  const store = useStore()
  const channel = store.getters.getSocketChannel
  let countdown = ref('')

  let chatCount = 0

  async function sendForm(msg) {
    if (inputMessage.value === '' && files.value.length == undefined) {
      return
    }

    if (files.value.length) {
      const imgPath = await uploadImage()
      msg.message = imgPath.data.path
      if (files.value[0].type.indexOf('image') > -1) {
        msg.messageType = 'img'
      } else {
        msg.messageType = 'file'
      }
      files.value = {}
      document.getElementById('uploadFile').value = ''
      sendMessage(msg)
    }
    if (inputMessage.value !== null && inputMessage.value !== '') {
      let msg = {
        message: inputMessage.value,
        sender: 'customer',
        messageType: 'text',
        uploading: true,
        chatNum: chatCount,
      }
      sendMessage(msg)
      inputMessage.value = null
    }
  }
  function uploadImage() {
    return new Promise((resolve, reject) => {
      var formdata = new FormData()
      formdata.append('photos', files.value[0])
      axios
        .post(`${config.baseUrl}/upload/`, formdata, {
          headers: { 'content-type': 'multipart/form-data' },
        })
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          reject(error)
        })
    })
  }

  async function sendMessage(msg) {
    if (msg.messageType !== 'img' && msg.messageType !== 'file') {
      await store.commit('updateStoreMessages', msg)
    }
    msg.channel = channel
    delete msg.uploading
    if (chatCount == 0) {
      warningMsgCountdown()
    }
    axios
      .post(`${config.baseUrl}/webchat/message`, msg)
      .then(function () {
        chatCount++
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  function onFileChange(e) {
    files.value = e.target.files || e.dataTransfer.files
    if (!files.value.length) {
      return
    }

    let size = formatBytes(files.value[0].size)
    const path = new FileReader()

    path.addEventListener('load', (e) => {
      var msg = {}
      if (files.value[0].type.indexOf('image') > -1) {
        msg = {
          message: e.target.result,
          fileName: files.value[0].name,
          fileSize: size,
          sender: 'customer',
          messageType: 'img',
          uploading: true,
          chatNum: chatCount,
        }
      } else {
        msg = {
          message: e.target.result,
          fileName: files.value[0].name,
          fileSize: size,
          sender: 'customer',
          messageType: 'file',
          uploading: true,
          chatNum: chatCount,
        }
      }
      store.commit('updateStoreMessages', msg)
      sendForm(msg)
    })

    path.readAsDataURL(files.value[0])
  }

  function formatBytes(size) {
    let units = ['B', 'KB', 'MB', 'GB', 'TB'],
      i

    for (i = 0; size >= 1024 && i < 4; i++) {
      size /= 1024
    }

    return size.toFixed(2) + ' ' + units[i]
  }

  function warningMsgCountdown() {
    let isSentWarningMsg = false
    countdown = setInterval(() => {
      let message =
        'ขณะนี้เจ้าหน้าที่กำลังให้บริการลูกค้าท่านอื่นอยู่ กรุณารอสักครู่'
      let msg = {
        message: message,
        sender: 'bot',
        messageType: 'text',
        date: new Date(),
      }
      store.commit('updateStoreMessages', msg)
      isSentWarningMsg = true
      if (isSentWarningMsg) {
        stopWarningMsg()
      }
    }, 7000)
  }

  function stopWarningMsg(countdown) {
    clearInterval(countdown)
  }

  return {
    countdown,
    inputMessage,
    files,
    sendForm,
    onFileChange,
    uploadImage,
    sendMessage,
    stopWarningMsg,
  }
}
//        .post('https://tb-nonevoice-api.herokuapp.com/upload/', formdata, {
