<template>
  <div v-bind="$attrs" :value="modelValue">
    <div
      v-for="msg in modelValue"
      :key="msg.id"
      class="message-line"
      :class="{
        'my-line': msg.sender == 'customer',
        'agent-line': msg.sender == 'agent' || msg.sender == 'bot',
        'img-error-line': msg.sender == 'image-error',
        'start-time-line': msg.sender == 'start-time',
      }"
    >
      <div
        class="agent-img"
        v-if="
          msg.sender !== 'customer' &&
          msg.sender !== 'image-error' &&
          msg.sender !== 'start-time' &&
          msg.messageType !== 'end chat without survey'
        "
      >
        <img
          v-if="msg.isLastMsg"
          :src="ttbLogo"
          class="mx-2 mb-3"
          alt="agent-logo"
        />
      </div>
      <div
        class="d-flex flex-column"
        :class="{
          'align-items-end me-2': msg.sender == 'customer',
          'align-items-start': msg.sender == 'agent' || msg.sender == 'bot',
        }"
      >
        <!-- ==== RENDER MESSAGE ===== -->
        <div
          :class="{
            'my-message': msg.sender == 'customer',
            'agent-message': msg.sender == 'agent' || msg.sender == 'bot',
            'img-message': msg.messageType == 'image',
            'file-message': msg.messageType == 'file',
          }"
        >
          <!-- ==== IMG ===== -->
          <BaseImageMessage
            v-if="msg.messageType == 'image'"
            :path="msg.message"
            @click="onShowImg(msg.message)"
          ></BaseImageMessage>
          <!-- ==== TEXT ===== -->
          <BaseTextMessage
            v-else-if="msg.messageType == 'text'"
            :message="msg.message"
          ></BaseTextMessage>
          <!-- ==== MENU ===== -->
          <BaseMenuMessage
            v-else-if="msg.messageType == 'menu'"
            :message="msg.message"
            :menu="msg.items"
          ></BaseMenuMessage>
          <!-- ==== FILE ===== -->
          <BaseFileMessage
            v-else-if="msg.messageType == 'file'"
            :message="msg.message"
            :name="msg.fileName"
            :size="msg.fileSize"
            style="cursor: pointer"
          ></BaseFileMessage>
          <BaseTextMessage
            v-else-if="msg.messageType == 'warning endchat'"
            :message="msg.message"
          ></BaseTextMessage>
          <!-- <BaseTextMessage
            v-else-if="msg.messageType == 'end chat without survey'"
            :message="msg.message"
          ></BaseTextMessage> -->
        </div>
        <div v-if="msg.uploading" class="message-time mt-1">กำลังส่ง...</div>
        <div
          v-if="
            !msg.uploading &&
            msg.isLastMsg &&
            msg.messageType !== 'end chat without survey' &&
            msg.sender !== 'image-error' &&
            msg.sender !== 'start-time'
          "
          class="message-time mt-1"
        >
          {{ msg.time }}
        </div>
      </div>
      <div v-if="msg.sender == 'image-error'" class="img-error">
        ไม่สามารถอัปโหลดไฟล์ได้ <br />
        กรุณาเลือกไฟล์ JPG, PNG, PDF ขนาดไฟล์ไม่เกิน 5Mb
      </div>
      <div v-if="msg.sender == 'start-time'" class="img-error">
        เริ่มการสนทนา {{ msg.message }}
      </div>
    </div>
  </div>
</template>

<script>
import setTimeChat from '@/use/setTimeChat'
export default {
  props: {
    modelValue: {
      type: Array,
    },
  },

  setup() {
    const { timeCondition } = setTimeChat()

    return {
      timeCondition,
    }
  },
  data() {
    return {
      isActive: true,
      ttbLogo: require('@/assets/ttb-logo.png'),
    }
  },
  mounted() {
    this.timeCondition(this.modelValue)
  },
  updated() {
    this.timeCondition(this.modelValue)
    let chatBox = document.querySelector('.chat-panel')
    setTimeout(() => {
      chatBox.scrollTop = chatBox.scrollHeight
    }, 100)
  },
  methods: {
    onShowImg(path) {
      // แบบเปิดเต็มจอ
      window.parent.postMessage(
        {
          imgPath: path,
        },
        '*'
      )

      // แบบเปิด tab ใหม่
      // if (path.indexOf('base64') > -1) {
      //   let newImg = new Image()
      //   newImg.src = path
      //   let w = window.open('')
      //   w.document
      //     .write(`<div style="background: #000; display: flex; justify-content: center; width: 100vw; height: 100vh;">
      //     ${newImg.outerHTML}
      //   </div>`)
      //   w.document.body.style.margin = '0'
      // } else {
      //   window.open(path, '_blank')
      // }
    },
  },
}
</script>
<style scoped>
.message-line {
  margin: 8px 0px;
  display: flex;
  flex-direction: column;
}

.my-line {
  align-items: flex-end;
}

.agent-line {
  flex-direction: row;
  align-items: flex-end;
}

.img-error-line {
  align-items: center;
}

.start-time-line {
  margin: 12px 0;
  align-items: center;
}

.img-error {
  color: #9eafc1;
  font-size: 12px;
  line-height: 20px;
}

.agent-img {
  width: 48px;
  height: 48px;
}

.my-message {
  background-color: #0055fe;
  color: #ffffff;
  padding: 10px;
  width: fit-content;
  block-size: fit-content;
  border-radius: 16px 16px 0px 16px;
  font-size: 14px;
  text-align: start;
  line-height: 20px;
}

.agent-message {
  text-align: start;
  background-color: #f5f4f6;
  padding: 10px;
  width: fit-content;
  block-size: fit-content;
  border-radius: 16px 16px 16px 0px;
  line-height: 20px;
}

.img-message {
  background-color: #ffffff;
  padding: 0;
  border-radius: 16px;
}

.file-message {
  border-radius: 16px;
}
.message-time {
  color: #9eafc1;
  font-size: 10px;
}
</style>
