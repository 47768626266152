<template>
  <div class="main">
    <div class="wrapMain">
      <div class="wrap">
        <!--Select with pure css-->
        <div class="select">
          <select
            :class="active ? 'select-active' : 'select-noActive'"
            @change="changeSelect()"
            v-model="value"
          >
            <option></option>
            <option
              v-for="(option, index) in options"
              :key="index"
              :value="option"
            >
              {{ option }}
            </option>
          </select>
          <label class="select-label">ต้องการให้เราช่วยเรื่องอะไร</label>
          <img src="../assets/ep_arrow-down-bold.svg" alt="" class="arrow" />
        </div>
        <!--Select with pure css-->
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import config from '../config'
export default {
  components: {},
  data() {
    return { active: false, options: [], value: '' }
  },
  updated() {
    this.$store.state.service = this.value
  },
  methods: {
    changeSelect() {
      this.active = true
    },
    getSubject() {
      axios.get(`${config.baseUrl}/webchat/subject`).then((res) => {
        if (res.status === 'success' || res.status === 200) {
          this.options = res.data.subject
        }
      })
    },
  },
  mounted() {
    this.getSubject()
  },
}
</script>
<style scoped>
.main {
  width: 19.375rem;
  position: relative;
  margin: auto;
}
.wrapMain {
  border: 1px solid #dfe6ec;
  border-radius: 12px;
  width: 100%;
  height: 3.75rem;
  position: relative;
}
.wrap {
  width: 100%;
}

/* select starting stylings ------------------------------*/
.select {
  position: relative;
}
select {
  border: unset;
  background-color: transparent;
  padding: 15px 12px 0px 12px;
}

.select-noActive {
  position: relative;
  font-family: inherit;
  background-color: transparent;
  width: 100%;
  height: 3.75rem;
  border: unset;
  border-radius: 12px;
  font-size: 16px;
  border-radius: 0;
  color: #002d63;
}
.select-active {
  position: relative;
  font-family: inherit;
  background-color: transparent;
  width: 100%;
  height: 3.75rem;
  border-radius: 12px;
  font-size: 16px;
  border-radius: 0;
  color: #002d63;
}

/* Remove focus */
.select-noActive:focus {
  outline: none;
  border: 1px solid #2e4c88;
  border-radius: 12px;
}
.select-active :focus {
  outline: none;
  border: unset;
}
.select-active:focus-visible {
  border: unset;
  outline: none;
}

/* Use custom arrow */
.select .select-active {
  appearance: none;
}
.select .select-noActive {
  appearance: none;
}

.arrow {
  position: absolute;
  top: 18px;
  right: 10px;
  cursor: pointer;
  z-index: -1;
}

/* LABEL ======================================= */
.select-label {
  color: #9eafc2;
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 0;
  height: 100%;
  transition: 0.2s ease all;
  padding: 15px 12px;
}

/* active state */
.select-active:focus ~ .select-label,
.select-active:valid ~ .select-label {
  color: #002d63;
  padding: 8px 12px;
  transition: 0.2s ease all;
  font-size: 12px;
}
</style>
