<template>
  <div class="message">
    <select name="" id="" class="">
      <option v-for="(option,index) in options" v-bind:key="index"> {{option}}</option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      options: [],
    }
  },
}
</script>
<style scoped>
.message {
  max-width: 25vh;
  overflow-wrap: break-word;
}
</style>
