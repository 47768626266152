<template>
  <div class="container d-grid justify-content-center">
    <!-- 
    ===== firstname =====
    -->
    <div
      class="form__group"
      :class="{ invalid: validate_firstname === 'invalid' }"
    >
      <!-- 
      ส่วนของการ Props
    -->
      <!-- <input
      type="text"
      class="form__field"
      placeholder="ชื่อ"
      name="name"
      id="name"
      required
      :disabled="isDisable"
    /> -->
      <!-- <label for="name" class="form__label">{{ title }}</label> -->
      <input
        type="text"
        class="form__field"
        placeholder="Firstname"
        name="firstname"
        id="firstname"
        required
        :disabled="isDisable"
        v-model="firstname"
        maxlength="50"
        @blur="validateFirstname"
      />
      <label for="firstname" class="form__label">ชื่อ</label>

      <!-- Text Alret Error -->
      <p v-if="validate_firstname === 'invalid'" class="d-flex">
        Please enter first name
      </p>
    </div>
    <!-- 
    ===== lastname =====
    -->
    <div
      class="form__group"
      :class="{ invalid: validate_lastname === 'invalid' }"
    >
      <input
        type="text"
        class="form__field"
        placeholder="Lastname"
        name="lastname"
        id="lastname"
        required
        :disabled="isDisable"
        v-model="lastname"
        maxlength="50"
        @blur="validateLastname"
      />
      <label for="lastname" class="form__label">นามสกุล</label>

      <!-- Text Alret Error -->
      <p v-if="validate_lastname === 'invalid'" class="d-flex">
        Please enter last name
      </p>
    </div>
    <!-- 
    ===== Emai =====
    -->
    <div class="form__group" :class="{ invalid: validate_email === 'invalid' }">
      <input
        type="text"
        class="form__field"
        placeholder="Email"
        name="email"
        id="email"
        required
        :disabled="isDisable"
        v-model="email"
        maxlength="50"
        @blur="validateEmail"
      />
      <label for="email" class="form__label">อีเมล</label>

      <!-- Text Alret Error -->
      <p v-if="validate_email === 'invalid'" class="d-flex">
        Please enter a valid email address
      </p>
    </div>
    <!-- 
    ===== Number =====
    -->
    <div
      class="form__group"
      :class="{ invalid: validate_number === 'invalid' }"
    >
      <input
        type="phone"
        class="form__field"
        placeholder="Number"
        name="number"
        id="number"
        required
        :disabled="isDisable"
        v-model="number"
        @blur="validateNumber"
        maxlength="15"
        @input="validateNumber"
      />
      <label for="number" class="form__label">โทรศัพท์มือถือ</label>

      <!-- Text Alret Error -->
      <p v-if="validate_number === 'invalid'" class="d-flex">
        Please input 10 digits number
      </p>
    </div>
    <!-- <BaseSelect
      PlaceHolder="ต้องการให้เราช่วยเรื่องอะไร"
      title="ต้องการให้เราช่วยเรื่องอะไร"
    /> -->
    <!-- <BaseButton Title="ส่งข้อมูล" :Enabled="Enabled" class="mt-5" /> -->
  </div>
</template>

<script>
import axios from 'axios'
import config from '../config'
import BaseButton from '../components/BaseButton.vue'
import BaseSelect from '../components/BaseSelect.vue'
export default {
  name: 'BaseForm',
  component: {
    BaseButton,
    BaseSelect,
  },
  props: {
    title: String,
    ErrorNessage: String,
    Type: String,
    PlaceHolder: String,
    Error: Boolean,
    isDisable: Boolean,
  },

  data() {
    return {
      firstname: '',
      lastname: '',
      email: '',
      number: '',
      disabledSubmit: true,
      validate_firstname: 'pending',
      validate_lastname: 'pending',
      validate_email: 'pending',
      validate_number: 'pending',
    }
  },
  methods: {
    validateFirstname() {
      if (this.firstname.trim() === '') {
        this.validate_firstname = 'invalid'
        this.disabledSubmit = true
      } else {
        this.validate_firstname = 'valid'
      }
    },
    validateLastname() {
      if (this.lastname.trim() === '') {
        this.validate_lastname = 'invalid'
        this.disabledSubmit = true
      } else {
        this.validate_lastname = 'valid'
      }
    },
    validateEmail() {
      if (this.email.trim() === '' || !this.email.includes('@')) {
        this.validate_email = 'invalid'
        this.disabledSubmit = true
      } else {
        this.validate_email = 'valid'
      }
    },
    validateNumber() {
      var x = this.number
        .replace(/\D/g, '')
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
      this.number = !x[2]
        ? x[1]
        : '' + x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '')
      if (this.number.trim() === '') {
        this.validate_number = 'invalid'
        this.disabledSubmit = true
      } else {
        this.validate_number = 'valid'
      }
    },
    methods: {
      validate() {
        if (this.firstName.trim() === '') {
          this.validation_firstName = 'invalid'
          return false
        } else {
          this.validation_firstName = 'valid'
        }
        if (this.lastName.trim() === '') {
          this.validation_lastName = 'invalid'
          return false
        } else {
          this.validation_lastName = 'valid'
        }
        if (this.email.trim() === '') {
          this.validation_email = 'invalid'
          return false
        } else {
          this.validation_email = 'valid'
        }
        if (this.phone.trim() === '') {
          this.validation_phone = 'invalid'
          return false
        } else {
          this.validation_phone = 'valid'
        }
        if (
          this.firstName === '' ||
          !this.firstName ||
          this.lastName === '' ||
          !this.lastName ||
          this.email === '' ||
          !this.email ||
          this.phone === '' ||
          !this.phone
        ) {
          this.checkValidate = true
        }
      },
      SubmitPreScreen() {
        this.validate()
        let $this = this
        if (!this.checkValidate) {
          let data = {
            firstName: $this.firstName,
            lastName: $this.lastName,
            email: $this.email,
            phone: $this.phone,
            subject: '',
          }
          axios
            .post(`${config.baseUrl}/prescreen`, data)
            .then((res) => {
              if (res.status == 200) {
                $this.$router.push({
                  name: 'Chat',
                  params: {
                    session: 'test',
                  },
                })
              }
            })
            .catch((error) => {
              this.errorPage(error)
              // $this.$router.push('error-page')
            })
        }
      },
      errorPage(error) {
        console.log(error)
        this.$router.push('/error-page')
      },
    },
  },
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.form__group {
  position: relative;
  padding: 8px 12px;
  /* margin-top: 10px; */
  border: 1px solid #dfe6ec;
  border-radius: 0.75rem;
  height: 3.75rem;
  max-width: 19.375rem;
  width: 19.365rem;
  margin: auto;
  margin-bottom: 0.75rem;
}
.form__field {
  width: 100%;
  border: 0;
  outline: 0;
  font-size: 1rem;
  color: #002d63;
  padding: 15px 0;
  background: transparent;
  transition: border-color 0.2s;
}
.form__field::placeholder {
  color: transparent;
}
.form__field:placeholder-shown ~ .form__label {
  font-size: 1rem;
  cursor: text;
  top: 20px;
  color: #9eafc2;
}
.form__label {
  position: absolute;
  top: 8.5px;
  display: block;
  transition: 0.2s;
  font-size: 0.75rem;
  color: #002d63;
}
.form__field:focus ~ .form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 0.75rem;
  color: #002d63;
  top: 8.5px;
}
.form__field:required,
.form__field:invalid {
  box-shadow: none;
}
/* 
==========================
    ส่วนของ Error
==========================
*/
.invalid {
  border-color: red !important;
  margin-bottom: 2rem;
}
.invalid p {
  margin-left: -0.7rem;
  color: red;
  font-size: 0.75rem;
}
</style>
